import { http } from './config'
import authHeader from './auth-header';

export default {


    

    lista_cancelados_xls: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {

        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto,

          };

       
          return http.get('/planilha-dash/lista-contrato-cancelados-xls', {
            params: params,
            responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
            headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui
          });

          
    },

    lista_inadimplencia_xls: (dataI, dataF, dataIR, dataFR, dataIE, dataFE,  nomeEmpresa, tipo, filtro, opcao, produto) => {

        const converteParaNull = (valor) => {
            return valor === null ? 'NULL' : valor;
        };

        const params = {
            'data-inicial': converteParaNull(dataI),
            'data-final': converteParaNull(dataF),
            'data-inicial-r': converteParaNull(dataIR),
            'data-final-r': converteParaNull(dataFR),
            'data-inicial-e': converteParaNull(dataIE),
            'data-final-e': converteParaNull(dataFE),
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto

          };

       
          return http.get('/planilha-dash/lista-inadimplencia-xls', {
            params: params,
            responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
            headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui

          });

          
    },

    lista_contrato_vendas_empresa_xls: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {

        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto
          };

       
          return http.get('/planilha-dash/lista-contrato-vendas-empresa-xls', {
            params: params,
            responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
            headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui

          });

          
    },

    lista_vendas_xls: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {

        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto
          };

       
          return http.get('/planilha-dash/lista-contrato-vendas-xls', {
            params: params,
            responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
            headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui

          });

          
    },

    lista_receita_xls: (dataI, dataF, dataIR, dataFR, dataIE, dataFE, nomeEmpresa, tipo, filtro, opcao, produto) => {

        const converteParaNull = (valor) => {
            return valor === null ? 'NULL' : valor;
        };

        
        const params = {
            'data-inicial': converteParaNull(dataI),
            'data-final': converteParaNull(dataF),
            'data-inicial-r': converteParaNull(dataIR),
            'data-final-r': converteParaNull(dataFR),
            'data-inicial-e': converteParaNull(dataIE),
            'data-final-e': converteParaNull(dataFE),
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto
          };

       
          return http.get('/planilha-dash/lista-contrato-receita-xls', {
            params: params,
            responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
            headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui

          });

          
    },


    lista_cancelados: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto, page, pageSize) => {
        const params = {
          'data-inicial': dataI,
          'data-final': dataF,
          'page': page,
          'size': pageSize,
          'empresa': nomeEmpresa,
          'tipo': tipo,
          'filtro': filtro,
          'opcao': opcao,
          'produto': produto,

        };
      
        return http.get('/contrato-dash/lista-contrato-cancelados', {
          params: params,
          headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui
        });
      },

      lista_vendas: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto, page, pageSize) => {

       const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'page': page,
            'size': pageSize,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto
        }
        // Fazer a solicitação HTTP usando o Axios e passar os cabeçalhos configurados
        return http.get('/contrato-dash/lista-contrato-vendas', {
            params: params,
            headers: authHeader() 
      });
      },

      lista_vendas_entre_empresas: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto, page, pageSize) => {

        const params = {
             'data-inicial': dataI,
             'data-final': dataF,
             'page': page,
             'size': pageSize,
             'empresa': nomeEmpresa,
             'tipo': tipo,
             'filtro': filtro,
             'opcao': opcao,
             'produto': produto
         }
         // Fazer a solicitação HTTP usando o Axios e passar os cabeçalhos configurados
         return http.get('/contrato-dash/lista-contrato-vendas-empresas', {
             params: params,
             headers: authHeader() 
       });
       },



      
      lista_acordos: (dataI, dataF, dataIR, dataFR, dataIE, dataFE, nomeEmpresa, tipo, filtro, opcao, produto,  page, pageSize) => {
            
        const converteParaNull = (valor) => {
            return valor === null ? 'NULL' : valor;
        };
  
        const  params ={
            'data-inicial': converteParaNull(dataI),
            'data-final': converteParaNull(dataF),
            'data-inicial-r': converteParaNull(dataIR),
            'data-final-r': converteParaNull(dataFR),
            'data-inicial-e': converteParaNull(dataIE),
            'data-final-e': converteParaNull(dataFE),
            'page': page,
            'size': pageSize,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto
        }

        // Fazer a solicitação HTTP usando o Axios e passar os cabeçalhos configurados
        return http.get('/contrato-dash/lista-contrato-acordos', {
            params: params,
            headers: authHeader() 
      });
    },

    lista_inadimplencia: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto, page, pageSize) => {
        // Fazer a solicitação HTTP usando o Axios e passar os cabeçalhos configurados
        const converteParaNull = (valor) => {
            return valor === null ? 'NULL' : valor;
        };

        const params = {
        'data-inicial': converteParaNull(dataI),
        'data-final': converteParaNull(dataF),
        'produto': produto,
        'empresa': nomeEmpresa,
        'tipo': tipo,
        'filtro': filtro,
        'opcao': opcao,
        'page': page,
        'size': pageSize,
    }
        return http.get('/contrato-dash/lista-inadimplencia', {
            params: params,
            headers: authHeader() 
          
    })
    },  

    
      



        lista_receita: (dataI, dataF, dataIR, dataFR, dataIE, dataFE, nomeEmpresa, tipo, filtro, opcao, produto,  page, pageSize) => {
            
            const converteParaNull = (valor) => {
                return valor === null ? 'NULL' : valor;
            };
      
            const  params ={
                'data-inicial': converteParaNull(dataI),
                'data-final': converteParaNull(dataF),
                'data-inicial-r': converteParaNull(dataIR),
                'data-final-r': converteParaNull(dataFR),
                'data-inicial-e': converteParaNull(dataIE),
                'data-final-e': converteParaNull(dataFE),
                'page': page,
                'size': pageSize,
                'empresa': nomeEmpresa,
                'tipo': tipo,
                'filtro': filtro,
                'opcao': opcao,
                'produto': produto,

            }
        
            // Fazer a solicitação HTTP usando o Axios e passar os cabeçalhos configurados
            return http.get('/contrato-dash/lista-contrato-receita', {
                params: params,
                headers: authHeader() 

          });
        },

        
        lista_acordos_xls: (dataI, dataF, dataIR, dataFR, dataIE, dataFE, nomeEmpresa, tipo, filtro, opcao, produto) => {

            const converteParaNull = (valor) => {
                return valor === null ? 'NULL' : valor;
            };
    
            const params = {
                'data-inicial': converteParaNull(dataI),
                'data-final': converteParaNull(dataF),
                'data-inicial-r': converteParaNull(dataIR),
                'data-final-r': converteParaNull(dataFR),
                'data-inicial-e': converteParaNull(dataIE),
                'data-final-e': converteParaNull(dataFE),
                'produto': produto,
                'empresa': nomeEmpresa,
                'tipo': tipo,
                'filtro': filtro,
                'opcao': opcao,
              };

              return http.get('/planilha-dash/lista-contrato-acordos-xls', {
                params: params,
                responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
                headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui
    
              });
           
             
              
        },


         
   

    total_titular: (dataI, dataF, nomeEmpresa, tipo, opcao, produto) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'opcao': opcao,
            'produto': produto,        }

        return http.get('/contrato-dash/total-titular', {
              params: params,
            headers: authHeader() 
        })
    },

    total_dependente: (dataI, dataF, nomeEmpresa, tipo, opcao, produto) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'opcao': opcao,
            'produto': produto,        }
        return http.get('/contrato-dash/total-dependente', {
            params: params,
            headers: authHeader() 
        })
    },

    total_agregado: (dataI, dataF, nomeEmpresa, tipo, opcao, produto) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'opcao': opcao,
            'produto': produto,        }
        return http.get('/contrato-dash/total-agregado', {
            params: params,
            headers: authHeader() 
              
        })
    },

    total_agrupar_idade_titular: (dataI, dataF, nomeEmpresa, tipo, opcao, produto) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'opcao': opcao,
            'produto': produto,              }

        return http.get('/contrato-dash/total-agrupar-idade-titular', {
            params: params,
            headers: authHeader() 
              
        })
    },


    total_agrupar_idade_dep: (dataI, dataF, nomeEmpresa, tipo, opcao, produto) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'opcao': opcao,
            'produto': produto,            }
        return http.get('/contrato-dash/total-agrupar-idade-dep/',  {
            params: params,
            headers: authHeader() 
              
        })
    },

    total_dependente_falecido: (dataI, dataF, nomeEmpresa, tipo,  opcao, produto) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'opcao': opcao,
            'produto': produto,
        }

        return http.get('/contrato-dash/total-dependente-falecido/',  {
             params: params,
            headers: authHeader()  
              
        })
    },

    total_agregado_falecido: (dataI, dataF, nomeEmpresa, tipo, opcao, produto) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'opcao': opcao,
            'produto': produto,        }

        return http.get('/contrato-dash/total-agregado-falecido', {
             params: params,
            headers: authHeader()  
              
        })
    },



    contrato_ativo: (dataI, dataF, nomeEmpresa, tipo, opcao, produto) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'opcao': opcao,
            'produto': produto,        }

        return http.get('/contrato-dash/total-contrato-ativo', {
             params: params,
            headers: authHeader()  
              
        })
    },


    total_vidas: (dataI, dataF, nomeEmpresa, tipo, opcao, produto) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'opcao': opcao,
            'produto': produto,


        }
        return http.get('/contrato-dash/total-vidas', {
            
            params: params,
            headers: authHeader()   
        })
    },

    total_cancelado: (dataI, dataF, nomeEmpresa, tipo, opcao, produto) => {
         const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'opcao': opcao,
            'produto': produto,
        }

        return http.get('/contrato-dash/total-cancelado', {
            params: params,
            headers: authHeader()   
              
        })
    },

    
    
    media_falecimento: (dataI, dataF, nomeEmpresa, tipo, opcao, produto) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'opcao': opcao,
            'produto': produto,
        }

        return http.get('/contrato-dash/media-falecimento', {
            params: params,
            headers: authHeader()   
              
        })
    },

    media_titular: (dataI, dataF, nomeEmpresa, tipo, opcao, produto) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'opcao': opcao,
            'produto': produto,
        }

        return http.get('/contrato-dash/media-titular', {
            params: params,
            headers: authHeader()   
              
        })
    },

    media_dependente: (dataI, dataF, nomeEmpresa, tipo, opcao, produto) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'opcao': opcao,
            'produto': produto,
        }

        return http.get('/contrato-dash/media-dependente', {
            params: params,
            headers: authHeader()   
              
        })
    },

    media_agregado: (dataI, dataF, nomeEmpresa, tipo, opcao, produto) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'opcao': opcao,
            'produto': produto,
        }

        return http.get('/contrato-dash/media-agregado', {
            params: params,
            headers: authHeader()   
              
        })
    },


    
    

    tipo_contrato: (dataI, dataF, nomeEmpresa, tipo, opcao, produto) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'opcao': opcao,
            'produto': produto,         }

        return http.get('/contrato-dash/tipo-contrato', {
            params: params,
            headers: authHeader()   
              
        })
    },

    motivo_cancelamento: (dataI, dataF, nomeEmpresa, tipo, opcao, produto) => {
       
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'opcao': opcao,
            'produto': produto,         }

        return http.get('/contrato-dash/motivo-cancelamento', {
             params: params,
            headers: authHeader()  
              
        })
    },

    contrato_sinistro: (dataI, dataF, nomeEmpresa, tipo, opcao, produto) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'opcao': opcao,
            'produto': produto,          }

        return http.get('/contrato-dash/contrato-sinistro', {
            params: params,
            headers: authHeader()  
              
        })
    },


    
    media_h_m: (dataI, dataF, nomeEmpresa, tipo, opcao, produto) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'opcao': opcao,
            'produto': produto,
        }

        return http.get('/contrato-dash/media-h-m', {
            params: params,
            headers: authHeader()  
              
        })
    },

    contrato_total_faixa_at: (dataI, dataF, nomeEmpresa, tipo, opcao, produto) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'opcao': opcao,
            'produto': produto
        }

        return http.get('/contrato-dash/total-contrato-ativo-faixa', {
            params: params,
            headers: authHeader()  
              
        })
    },


    contrato_total_faixa_ca: (dataI, dataF, nomeEmpresa, tipo, opcao, produto) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'opcao': opcao,
            'produto': produto
        }

        return http.get('/contrato-dash/total-contrato-cancelado-faixa', {
            params: params,
            headers: authHeader()  
              
        })
    },
    
    
    downloadFicha: (id) => {
        const params = new URLSearchParams([['id', id]])
        return http.get('download_ficha', { params }, { responseType: 'blob' },
        )
    },

    downloadZip: (id) => {
        const params = new URLSearchParams([['id', id]])
        return http.get('download_zip', { params }, { responseType: 'blob' },
        )
    },

}
